@font-face {
	font-family: "gotham";
	src: url("/static/font/Gotham/Gotham-Book.woff2") format("woff2"),
		url("/static/font/Gotham/Gotham-Book.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "gotham-bold";
	src: url("/static/font/Gotham/Gotham-Bold.woff2") format("woff2"),
		url("/static/font/Gotham/Gotham-Bold.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
