:root {
	--font-family: "gotham", sans-serif;
	--font-family-bold: "gotham-bold", sans-serif;
	// default font-sizes are defined in _common-typography.scss. Override the default font sizes here.
	--font-weight-bold-xl10: bold;
	--font-weight-bold-xl9: bold;
	--font-weight-bold-xl8: bold;
	--font-weight-bold-xl7: bold;
	--font-weight-bold-xl6: normal;
	--font-weight-bold-xl5: bold;
	--font-weight-bold-xl4: bold;
	--font-weight-bold-xl3: bold;
	--font-weight-bold-xl2: bold;
	--font-weight-bold-xl: bold;
	--font-weight-bold-large: bold;
	--font-weight-bold-regular: bold;
	--font-weight-bold-small: bold;
	--font-weight-bold-xsmall: bold;
	--font-weight-bold-xxsmall: bold;
}
